<i18n lang="yaml">
pt:
  moreAbout: 'Mais sobre isto'
en:
  moreAbout: 'More about this'
</i18n>
<template>
  <span>
    <deck-tooltip
      position="right"
      trigger="click"
      :offset="-30"
      kind="info"
    >
      <template #activator="{ props }">
        <deck-button
          is-ready
          color="#64b5f6"
          icon="circle-info"
          kind="inline"
          icon-kind="solid"
          :aria-label="t('moreAbout')"
          :tooltip-props="{
            position: 'right',
          }"
          v-bind="props"
        />
      </template>

      <template #content>
        <!-- @slot Default slot to render custom content in the tooltip -->
        <slot>
          <p class="mb-0">
            {{ text }}
          </p>
        </slot>
      </template>
    </deck-tooltip>
  </span>
</template>
<script>

/**
 * A custom tooltip component made specific for system hints
 */
export default defineComponent({
  name: 'DeckHinter',
  props: {
    /**
     * The text to be rendered inside the Hinter tooltip.
     * @type {string}
     */
    text: {
      type: String,
      default: '',
    },
  },
  setup() {
    return {
      t: useI18n().t,
    };
  },
});
</script>
